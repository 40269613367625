<template>
    <d-modal v-if="modal.open" @close="closeHandler">
        <d-modal-header>
            <d-modal-title>Detail foto verwijderen</d-modal-title>
        </d-modal-header>
        <img :src="`${$dotenv('PHOTOSTUDIO_BASE_URL')}${modal.photo.path}`" width="100%" alt="">
        <d-modal-body>
            <p>Weet je zeker dat je bovenstaande foto wilt verwijderen</p>
            <button class="btn btn-danger pull-right" @click="deletePhoto">
                <i class="material-icons">delete</i> Verwijderen
            </button>
            <button class="btn btn-primary" style="float: right" @click="closeHandler">
                <i class="material-icons">arrow_left</i> Nee, breng me terug
            </button>
        </d-modal-body>
    </d-modal>
</template>

<script>
export default {
    props: {
        modal: {
            required: true
        }
    },
    data() {
        return {}
    },
    methods: {
        closeHandler() {
            this.modal.open = false
            this.$emit('closeHandler', this.modal.open)
        },
        async deletePhoto() {
            try {
                await this.$store.dispatch('photoStudio.studio/deletePhoto', {path: this.modal.photo.path})
                this.$emit('confirmed')
                this.closeHandler()
            } catch (e) {
            }
        }
    }
}
</script>
