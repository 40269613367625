<template>
    <d-container fluid>
        <pageTitle subtitle="Fotostudio"
                   title="Photobooth 2."/>
        <d-row>
            <vCard :col="8"
                   :header="`Foto's - details (${ picturesTaken > 0 ? picturesTaken : 'loading...' }) refresh in (${this.refreshInterval})`">
                <div class="ftp-photo-wrapper">
                    <div v-if="photos" v-for="(value, key) in photos">
                        <div class="photo-container">
                            <img :src="`${$dotenv('PHOTOSTUDIO_BASE_URL')}/${value}`" alt="" width="200">
                            <div @click="maximizeImage(value)">
                                <i class="material-icons">zoom_in</i>
                            </div>
                        </div>
                        <button class="btn btn-danger" @click="removePhoto(value)">
                            <i class="material-icons">delete</i>
                            Verwijderen
                        </button>
                    </div>
                </div>
            </vCard>
            <vCard :col="4" header="Confirm">
                <v-input label="Kenteken" placeHolderText="Voorbeeld (19-TGS-2)" v-model="form.licensePlate"
                         :show-errors="true"
                         :auto-complete-list="process"
                         auto-complete-key="licenseplate"
                         auto-complete-label="licenseplate"/>
                <button class="btn btn-primary" :disabled="picturesTaken <= 0" @click="confirmPhotos">Opslaan
                </button>
            </vCard>
        </d-row>
        <deletePhotoModal :modal="deletePhotoConfirmModal"
                          @closeHandler="(e) => { deletePhotoConfirmModal.open = e }"/>
        <maximizePhotoModal :modal="maximizePhotoModalData"
                            @closeHandler="(e) => { maximizePhotoModalData.open = e }"/>
    </d-container>
</template>

<script>
import baseUpload from "@/modules/photoStudio/pages/baseUpload";
import deletePhotoModal from './../modals/deleteDetailPhoto'
import {imagesByType} from "@/modules/photoStudio/requests/photostudio.request";

export default {
    title: 'Details',
    components: {deletePhotoModal},
    extends: baseUpload,
    data() {
        return {
            deletePhotoConfirmModal: {
                open: false,
                photo: {
                    id: null,
                    url: null
                }
            }
        }
    },
    methods: {
        async getPhotos() {
            this.photos = await imagesByType('details2')
        },
        async removePhoto(value) {
            let arr = value.split('/')
            this.deletePhotoConfirmModal.photo = {
                id: arr[arr.length - 1],
                path: value
            }
            this.deletePhotoConfirmModal.open = true
        },
        async confirmPhotos() {
            this.$v.validate(this.form, async (form) => {
                try {
                    await this.$store.dispatch('photoStudio.studio/confirmPhotos', {
                        licensePlate: form.licensePlate,
                        type: 'details2'
                    })
                    this.form.licensePlate.value = null
                    this.photos = []
                } catch (e) {
                }
            })
        }
    }
}
</script>
