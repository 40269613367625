<script>
import {isLicensePlate} from "@/utils/validator";
import maximizePhotoModal from './../modals/maximizePhoto'
import {mapState} from 'vuex';

export default {
    title: 'baseUpload',
    components: { maximizePhotoModal },
    computed: {
        ...mapState('photoStudio.studio', ['process']),
        picturesTaken() {
            return this.photos !== null ? Object.keys(this.photos).length : -1
        }
    },
    data() {
        return {
            form: {
                licensePlate: {
                    validations: {
                        isLicensePlate
                    }
                }
            },
            photos: [],
            maximizePhotoModalData: {
                open: false,
                photo: {
                    path: null
                }
            },
            refresh: null,
            refreshInterval: 5,
        }
    },
    created() {
        this.getPhotos()
        this.refresh = this.autoRefresh()
    },
    beforeDestroy() {
        clearInterval(this.refresh)
    },
    methods: {
        autoRefresh() {
            return setInterval(function () {
                this.getPhotos()
                this.refreshInterval = 5
                for (let i = 0; i < 5; i++) {
                    setTimeout(() => {
                        this.refreshInterval--
                    }, 1000 * i)
                }
            }.bind(this), 5000);
        },
        maximizeImage(path) {
            this.maximizePhotoModalData.photo.path = path
            this.maximizePhotoModalData.open = true
        }
    }
}
</script>

<style lang="scss">
.ftp-photo-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.ftp-photo-wrapper > div {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid black;
    border-radius: 5px;
}

.ftp-photo-wrapper > div > div > img {
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid black;
    width: 100%;
}

.ftp-photo-wrapper > div > button {
    margin: 10px 0;
}


.photo-container {
    position: relative;


    &:hover {
        > div {
            display: flex;
        }
    }

    > div {
        position: absolute;
        display: none;
        align-items: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.3);

        > i {
            margin: 0 auto;
            display: block;
            font-size: 100px;
            color: white
        }
    }
}
</style>
