<template>
    <d-modal v-if="modal.open" @close="closeHandler" size="lg">
        <d-modal-header>
            <d-modal-title>Vergrote weergave</d-modal-title>
        </d-modal-header>
        <img :src="`${url()}`" alt="" width="100%">
    </d-modal>
</template>

<script>

export default {
    props: {
        modal: {
            required: true
        }
    },
    data() {
        return {}
    },
    methods: {
        url() {
            if (this.modal.photo.path.startsWith('https://')) {
                return this.modal.photo.path
            } else {
                return `${this.$dotenv('PHOTOSTUDIO_BASE_URL')}/${this.modal.photo.path}`
            }
        },
        closeHandler() {
            this.modal.open = false
            this.$emit('closeHandler', this.modal.open)
        }
    }
}
</script>
